import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/", // 首页
    name: "index",
    component: () =>
      import(/* webpackChunkName: "index" */ "../views/index.vue"),
  },
  {
    path: "/search", // 首页搜索
    name: "search",
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/search.vue"),
  },
  {
    path: "/login", // 登录
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login.vue"),
  },
  {
    path: "/workSearch", // 职位搜索
    name: "workSearch",
    component: () =>
      import(/* webpackChunkName: "workSearch" */ "../views/workSearch.vue"),
  },
  {
    path: "/campusRecruitment", // 校园招聘
    name: "campusRecruitment",
    component: () =>
      import(
        /* webpackChunkName: "campusRecruitment" */ "../views/campusRecruitment.vue"
      ),
  },
  {
    path: "/overseas", // 海外专场
    name: "overseas",
    component: () =>
      import(/* webpackChunkName: "overseas" */ "../views/overseas.vue"),
  },
  {
    path: "/brand", // 品牌介绍
    name: "brand",
    component: () =>
      import(/* webpackChunkName: "brand" */ "../views/brand.vue"),
  },
  {
    path: "/company", // 公司介绍
    name: "company",
    component: () =>
      import(/* webpackChunkName: "company" */ "../views/company.vue"),
  },
  {
    path: "/position", // 职位详情
    name: "position",
    component: () =>
      import(/* webpackChunkName: "position" */ "../views/position.vue"),
  },
  {
    path: "/about", // 关于我们
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about.vue"),
  },
  {
    path: "/contact", // 联系我们
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/contact.vue"),
  },
  {
    path: "/my_deliver", // 我的投递
    name: "my_deliver",
    component: () =>
      import(/* webpackChunkName: "my_deliver" */ "../views/my_deliver.vue"),
  },
  {
    path: "/notes", // 在线简历
    name: "notes",
    component: () =>
      import(/* webpackChunkName: "notes" */ "../views/notes.vue"),
  },
  {
    path: "/my_notes", // 简历中心
    name: "my_notes",
    component: () =>
      import(/* webpackChunkName: "my_notes" */ "../views/my_notes.vue"),
  },
  {
    path: "/settingUser", // 个人中心
    name: "settingUser",
    component: () =>
      import(/* webpackChunkName: "settingUser" */ "../views/settingUser.vue"),
  },
  {
    path: "/zhengfu", // 政府引才项目
    name: "zhengfu",
    component: () =>
      import(/* webpackChunkName: "zhengfu" */ "../views/zhengfu.vue"),
  },
  {
    path: "/zhengfu1", // 政府引才项目1
    name: "zhengfu1",
    component: () =>
      import(/* webpackChunkName: "zhengfu1" */ "../views/zhengfu1.vue"),
  },
  {
    path: "/zhengfu2", // 政府引才项目1
    name: "zhengfu2",
    component: () =>
      import(/* webpackChunkName: "zhengfu2" */ "../views/zhengfu2.vue"),
  },
  {
    path: "/agreement", // 所有协议页面
    name: "agreement",
    component: () =>
      import(/* webpackChunkName: "agreement" */ "../views/agreement.vue"),
  },
  {
    path: "/message", // 消息
    name: "message",
    component: () =>
      import(/* webpackChunkName: "message" */ "../views/message.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;

import Vue from "vue";
import "element-ui/lib/theme-chalk/index.css";

// fade/zoom 等
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

Vue.component(CollapseTransition.name, CollapseTransition)

import {
  Button,
  Drawer,
  Dialog,
  Breadcrumb,
  BreadcrumbItem,
  Pagination,
  Select,
  Option,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  Link,
  Popover,
  Cascader,
  Tooltip,
  Message,
  Upload,
  Empty,
  Image,
  Switch,
  CheckboxGroup,
  Checkbox,
  RadioGroup,
  Radio,
  DatePicker,
  Table,
  TableColumn,
  Carousel,
  CarouselItem,
  Calendar,
  Progress,
} from "element-ui";

Vue.use(Button);
Vue.use(Drawer);

Vue.use(Dialog);

Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Pagination);
Vue.use(Select);
Vue.use(Option);

Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Input);
Vue.use(Link);
Vue.use(Popover);
Vue.use(Cascader);
Vue.use(Tooltip);
Vue.use(Upload);
Vue.use(Empty);
Vue.use(Image);
Vue.use(Switch);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(DatePicker);

Vue.use(Table);
Vue.use(TableColumn);

Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Calendar);

Vue.use(Progress);


Vue.prototype.$message = Message;

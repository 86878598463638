<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
document.getElementsByTagName("html")[0].style.fontSize =
  document.documentElement.clientWidth / 7.5 + "px";
window.onresize = function () {
  document.getElementsByTagName("html")[0].style.fontSize =
    document.documentElement.clientWidth / 7.5 + "px";
};
import { getusersig,changim } from '@/api/index.js';

export default {
  name: "App",
  watch: {
    $route(e) {
      /* 跳转到页面顶部 */
      window.scrollTo(0, 0);
    },
  },

  
  created() {},
  mounted() {
    let addr = this.$store.state.point;
    if (!addr.latitude) {
      this.$store.dispatch("getArea");
    }
        let y =  localStorage.getItem('LoginStatus')
   if(y&&Number(y)){
    let k = localStorage.getItem('userId')
    this.logins(k)
    console.log('k :>> ', k);
   }
  },
    methods: {

    //im 登录
		async	logins(uname) {

				let that = this
				console.log('hah', uname);
				// let userSig = genTestUserSig(uname.toString()).userSig
					let userSig =await	getusersig({memberId:uname})
				let promise = this.tim.login({
					userID: uname.toString(),
					userSig: userSig
				});
				promise.then(function(imResponse) {
					console.log(imResponse.data, `登录成功${uname}`); // 登录成功
					console.log(imResponse.data.errorInfo);
					changim({id:uname}).then(res=>{
						
					})

					if (imResponse.data.repeatLogin === true) {
						// 标识帐号已登录，本次登录操作为重复登录。v2.5.1 起支持
					}

				}).catch(function(imError) {
					console.warn('login error:', imError); // 登录失败的相关信息
				});
			},
  },
};
</script>

<style lang="scss">

</style>

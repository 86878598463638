import request from "@/utils/request";

// -------------------------------------------------------------- 接口区 -------------------------------------------------------------- //

// 短信验证码注册-登录
export function smsLogin(data) {
  return request({
    url: "/admin-api/web/s-admin/registerLogin",
    method: "POST",
    data,
  });
}


//改变im注册状态
export function changim(params) {			
	// console.log(data,444444444444,data['signature']);
	return request({
// ?id=${data.id}
		url: `/admin-api/web/s-admin/updates`,
		method: 'get',
		// data,
		params,
		noLoad: true
		// ,aiyoue:true
	})
}
//获取usersig  --- im
export function getusersig(params) {			
	// console.log(data,444444444444,data['signature']);
	return request({

		url: '/admin-api/web/s-admin/gets',
		method: 'get',
		params,
		noLoad: true
		// ,aiyouee:true
	})
}


// 发送手机验证码
export function sendSmsCode(data) {
  return request({
    url: "/admin-api/system/auth/send-sms-code",
    method: "POST",
    data,
    headers: {
      'tenant-id': "1"
    },
  });
}



// 获取用户信息
export function getshield(params) {
  return request({
    url: "/admin-api/web/s-admin/get",
    method: "GET",
    params,
  });
}



// 热门职位
export function hots(params) {
  return request({
    url: "/admin-api/web/position-log/hots",
    method: "GET",
    params,
  });
}

// 首页热招职位
export function search(params) {
  return request({
    url: "/admin-api/web/position/pages",
    method: "GET",
    params,
  });
}

// 职位搜索页面-----职位列表
export function getsearch(params) {
  return request({
    url: "/admin-api/web/position/search",
    method: "GET",
    params,
  });
}


// 行业分类
export function geta(params) {
  return request({
    url: "/admin-api/web/enter/geta",
    method: "GET",
    params,
  });
}

// 首页大轮播图
export function guanggao(params) {
  return request({
    url: "/admin-api/web/enter/guanggao",
    method: "GET",
    params,
  });
}

// 企业推荐
export function recommend(params) {
  return request({
    url: "/admin-api/web/position/recommend",
    method: "GET",
    params,
  });
}

// 我的投递简历  被查看  收藏  邀面试  不合适
export function viewtest(params) {
  return request({
    url: "/admin-api/web/position-log/viewtest",
    method: "GET",
    params,
  });
}


// 校园招聘
export function xiaoyuan(params) {
  return request({
    url: "/admin-api/web/enter/xiaoyuan",
    method: "GET",
    params,
  });
}

// 职位详情
export function getPositionInfo(params) {
  return request({
    url: "/admin-api/web/position/getPositionInfo",
    method: "GET",
    params,
  });
}

// 品牌
export function getbrand(params) {
  return request({
    url: "/admin-api/web/enter/brand",
    method: "GET",
    params,
  });
}



// 政府机关在线数量以及岗位数量
export function getnum(params) {
  return request({
    url: "/admin-api/web/enter/getnum",
    method: "GET",
    params,
  });
}

// 政府职位搜索---政府招聘文章  人才招聘
export function searchs(params) {
  return request({
    url: "/admin-api/web/position/searchs",
    method: "GET",
    params,
  });
}

// 政府更多企业
export function searchss(params) {
  return request({
    url: "/admin-api/web/position/searchss",
    method: "GET",
    params,
  });
}



// 获得海外校园热招
export function overseas(params) {
  return request({
    url: "/admin-api/web/enter/overseas",
    method: "GET",
    params,
  });
}
// 品牌--海外
export function brandss(params) {
  return request({
    url: "/admin-api/web/enter/brandss",
    method: "GET",
    params,
  });
}




// 公司详情
export function getget(params) {
  return request({
    url: "/admin-api/web/enter/get",
    method: "GET",
    params,
  });
}

// 公司发布的职位
export function positionNum(params) {
  return request({
    url: "/admin-api/web/position/positionNum",
    method: "GET",
    params,
  });
}

export function positionNums(params) {
  return request({
    url: "/admin-api/web/position/positionNums",
    method: "GET",
    params,
  });
}



// 职位收藏
export function create(data) {
  return request({
    url: "/admin-api/web/position-log/create",
    method: "POST",
    data,
  });
}

// 取消收藏
export function update(data) {
  return request({
    url: "/admin-api/web/position-log/update",
    method: "PUT",
    data,
  });
}



// 我的简历列表
export function gets(params) {
  return request({
    url: "/admin-api/web/seekers/gets",
    method: "GET",
    params,
  });
}



// 关于我们
export function AboutUs(params) {
  return request({
    url: "/admin-api/web/about/list",
    method: "GET",
    params,
  });
}


// 投递简历
export function sendresume(data) {
  return request({
    url: "/admin-api/web/position-log/send-resume",
    method: "POST",
    data,
  });
}

// 新增简历
export function getcreate(data) {
  return request({
    url: "/admin-api/web/seekers/create",
    method: "POST",
    data,
  });
}

// 上传
export function upload(data) {
  return request({
    url: "/admin-api/infra/file/upload",
    method: "POST",
    data,
  });
}

// 求职者修改简历
export function updateSeekers(data) {
  return request({
    url: "/admin-api/web/seekers/updateSeekers",
    method: "POST",
    data,
  });
}

// 求职者简历详情
export function getResExp(params) {
  return request({
    url: "/admin-api/web/seekers/getResExp",
    method: "GET",
    params,
  });
}

// 获取学历字典
export function getpage(params) {
  return request({
    url: "/admin-api/system/dict-data/page",
    method: "GET",
    params,
  });
}

// 删除教育经历
export function getdelete(params) {
  return request({
    url: "/admin-api/web/education-exper/delete",
    method: "DELETE",
    params,
  });
}

// 删除工作经历
export function getdeletes(params) {
  return request({
    url: "/admin-api/web/work-exper/delete",
    method: "DELETE",
    params,
  });
}

// 删除项目经历
export function getdeletess(params) {
  return request({
    url: "/admin-api/web/project-exper/delete",
    method: "DELETE",
    params,
  });
}

export function getdeletessss(params) {
  return request({
    url: "/admin-api/web/production/delete",
    method: "DELETE",
    params,
  });
}


export function getdeletesss(params) {
  return request({
    url: "/admin-api/web/qualification/delete",
    method: "DELETE",
    params,
  });
}

// 账号设置-修改
export function updateid(data) {
  return request({
    url: "/admin-api/web/s-admin/updateid",
    method: "PUT",
    data,
  });
}

// 评论区新增
export function Plcreate(data) {
  return request({
    url: "/admin-api/web/comment/create",
    method: "POST",
    data,
  });
}

// 评论区删除
export function Pldelete(params) {
  return request({
    url: "/admin-api/web/comment/delete",
    method: "DELETE",
    params,
  });
}

// 评论列表
export function Plpage(params) {
  return request({
    url: "/admin-api/web/comment/page",
    method: "GET",
    params,
  });
}


// 厅级部门列表
export function mbpage(params) {
  return request({
    url: "/admin-api/web/department/page",
    method: "GET",
    params,
  });
}

// 厅级部门下的公司列表
export function mbsearchss(params) {
  return request({
    url: "/admin-api/web/position/searchss",
    method: "GET",
    params,
  });
}


// 行业分类
export function hyflpage(params) {
  return request({
    url: "/admin-api/web/zidian/page",
    method: "GET",
    params,
  });
}

// 人才招聘周直播活动
export function zhibopage(params) {
  return request({
    url: "/admin-api/web/zhibo/page",
    method: "GET",
    params,
  });
}


// 微信扫码
export function tokena(params) {
  return request({
    url: "admin-api/web/s-admin/tokena",
    method: "GET",
    params,
  });
}

// export function erweima(params) {
//   return request({
//     url: "admin-api/web/s-admin/erweima",
//     method: "GET",
//     params,
//   });
// }

// 谁看过我的简历
export function shui(params) {
  return request({
    url: "admin-api/web/position-log/shui",
    method: "GET",
    params,
  });
}


export function jldelete(params) {
  return request({
    url: "admin-api/web/seekers/delete",
    method: "DELETE",
    params,
  });
}




// 屏蔽企业
export function pbupdate(data) {
  return request({
    url: "/admin-api/web/s-admin/update",
    method: "PUT",
    data,
  });
}


// 公司列表
export function gslist(params) {
  return request({
    url: "admin-api/web/enter/pages",
    method: "GET",
    params,
  });
}


// 获取面试邀请信息
export function msyqinfo(params) {
  return request({
    url: "admin-api/web/interview/get",
    method: "GET",
    params,
  });
}

// 同意，拒接面试邀请
export function tyjjupdatea(data) {
  return request({
    url: "admin-api/web/position-log/updatea",
    method: "PUT",
    data,
  });
}


// 用户接收的信息
export function jsxxget(params) {
  return request({
    url: "admin-api/web/message/get",
    method: "GET",
    params,
  });
}

// 修改接收消息的状态  查看
export function updateState(data) {
  return request({
    url: "admin-api/web/message/updateState",
    method: "PUT",
    data,
  });
}


export function ymswcz(params) {
  return request({
    url: "admin-api/web/position-log/gets",
    method: "GET",
    params,
  });
}


//  所属行业-分页
export function zidianPages(params) {
  return request({
    url: "admin-api/web/zidian/pages",
    method: "GET",
    params,
  });
}


export function erweima(params) {
  return request({
    url: "admin-api/web/s-admin/erweima",
    method: "GET",
    params,
  });
}

export function yanzheng(params) {
  return request({
    url: "admin-api/web/s-admin/yanzheng",
    method: "GET",
    params,
  });
}


export function ycupdate(data) {
  return request({
    url: "admin-api/web/seekers/update",
    method: "PUT",
    data,
  });
}















import axios from "axios";
import { Message, Loading } from "element-ui";
import store from "@/store";
import router from "@/router";

let loading = null;

const service = axios.create({
  
  baseURL: "https://ht.changanjoin.com" //  线上
  // baseURL: " http://192.168.2.112:48080" // 本地
});


// request interceptor
service.interceptors.request.use(
  config => {
    let token = localStorage.getItem('token')   //正式
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`
      };
    }
    loading = Loading.service({
      lock: false,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)"
    });
    return config;
  },
  error => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    loading.close();
    const res = response.data;
    if (res.code === 0) {
      return res;
    } else if (res.code === 401) {
      Message({
        message: "登录过期，请重新登录！",
        type: "error"
      });

      let qrCode = localStorage.getItem('qrCode')
      localStorage.clear()
      localStorage.setItem("qrCode", qrCode)
      
      setTimeout(() => {
        router.replace("/login");
      }, 800);
      return res;
    } else {
      return res;
      // Message.error(res.msg);
    }
    
    return Promise.reject(res.msg);
  },
  error => {
    console.log("err" + error); // for debug
    Message({
      message: error.msg,
      type: "error"
    });
    return Promise.reject(error);
  }
);

const get = (url, params, loading = false) =>
  service({
    method: "get",
    url,
    params,
    loading
  });

const post = (url, params, data, loading = true) =>
  service({
    method: "post",
    url,
    params,
    data,
    loading
  });

const put = (url, params, data, loading = true) =>
  service({
    method: "put",
    url,
    params,
    data,
    loading
  });

const del = (url, params, data, loading = true) =>
  service({
    method: "delete",
    url,
    params,
    data,
    loading
  });

export { get, post, put, del };
export default service;
